import React, {Component} from 'react';
import lib from 'library'

const styles ={
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 150,
    //backgroundColor: 'rgba(57,169,71,1)',
    //fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  container:{
    width: 1024,
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    color: 'rgba(53,107,179,1)',
    fontSize: 30,
    fontFamily: 'HelveticaNeueLTStd-Bd',
  },
  content: {
    color: 'black',
    fontSize: 22,
    fontFamily: 'HelveticaNeueLTStd-ThEx',
    padding: 15,
  }
}


class SectionInfo4 extends Component {


  render(){


    return(
      <div style={styles.footerStyle}>
        <div style={styles.container}>

          <div style={{
              display: 'flex',
              flex: 1,
            }}>
            <p style={styles.content}>
              Totto
              <br></br>
              TP Holiday Group Limited
              <br></br>
              Pro Bags Peru S.A.C.
              <br></br>
              Heritage Travelware Ltd
              <br></br>
              Alentino Group
              <br></br>
              JAC Corporation
              <br></br>
              Radu Importacao E Exportacao Ltda

              </p>
          </div>
          <div style={{
              maxWidth: 350,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>

          </div>



        </div>

      </div>
    )
  }
}

export default SectionInfo4
