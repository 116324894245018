import React, {Component} from 'react';
import Helmet from 'react-helmet'
import lib from 'library'

import TitleBar from 'components/TitleBar.js'
import SectionInfo0 from 'components/SectionInfo0.js'
import SectionInfo1 from 'components/SectionInfo1.js'
import SectionInfo2 from 'components/SectionInfo2.js'
import SectionInfo3 from 'components/SectionInfo3.js'
import SectionInfo4 from 'components/SectionInfo4.js'
import Layout from "components/layout"

export default class App extends Component {
  render(){

    const lang = lib.lc.currentLang(this.props.location.pathname)

    return(

      <Layout pathname={this.props.location.pathname}>

          <div >
            <Helmet
              title={lc[lang].pagename}
              meta={[
                { name: 'description', content: '冠信控股有限公司成立於2014年，主要業務是代工生產箱包、背包和旅行配件。' },
                { name: 'keywords', content: '冠信文化創意有限公司, 冠信, 行李箱, 背包, 旅行配件' },
              ]}
            />
            <div style={{height: 50}} />
            <TitleBar title={lc[lang].title1} />
            <SectionInfo0 pathname={this.props.location.pathname} />
            <TitleBar title={lc[lang].title2} />
            <SectionInfo1 pathname={this.props.location.pathname} />
            <TitleBar title={lc[lang].title3} />
            <SectionInfo2 pathname={this.props.location.pathname} />
            <TitleBar title={lc[lang].title4} />
            <SectionInfo3 pathname={this.props.location.pathname} />
            <TitleBar title={lc[lang].title5} />
            <SectionInfo4 pathname={this.props.location.pathname} />
            <div style={{height: 100}} />

          </div>
</Layout>
    )
  }
}


const lc = {
  en:{
    title1: 'Guanxin Culture Creativity Co Limited',
    title2: 'Graphic Design',
    title3: 'Cultural and Creative Planning',
    title4: 'Branding',
    title5: 'Major Clients',
  },
  zh:{
    title1: '冠信文化創意有限公司',
    title2: '平面設計',
    title3: '文化創意策劃',
    title4: '品牌策劃',
    title5: '主要客戶',
  }
}
