import React, {Component} from 'react';
import lib from 'library'

const styles ={
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 250,
    //backgroundColor: 'rgba(57,169,71,1)',
    //fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  container:{
    width: 1024,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  title: {
    color: 'rgba(53,107,179,1)',
    fontSize: 30,
    fontFamily: 'HelveticaNeueLTStd-Bd',
  },
  content: {
    color: 'black',
    fontSize: 22,
    fontFamily: 'HelveticaNeueLTStd-ThEx',
    padding: 15,
  }
}


class SectionInfo0 extends Component {


  render(){

    const lang = lib.lc.currentLang(this.props.pathname)

    return(
      <div style={styles.footerStyle}>
        <div style={styles.container}>

          <div style={{
              display: 'flex',
              flex: 1,
              minWidth: 350,
            }}>
            <p style={styles.content}>
              {lc[lang].info1}
              </p>
          </div>
          <div style={{
              width: 350,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img src={require('assets/image/Guanxin.jpg')} alt='Guanxin' />
          </div>



        </div>

      </div>
    )
  }
}

const lc = {
  en:{
    info1: 'The professional design team of Guanxin Culture Creativity Co Limited provides various luggage design services according to the needs of guests, including luggage, cloth cases, backpacks, bags, multi-functional backpacks and so on.',
  },
  zh:{
    info1: '褔清市冠信文化創意有限公司的專業設計團隊，根據客人不同的需要，提供各類箱包設計服務，包括行李箱、布箱、背包、豋山包、多功能背包等。',
  }
}

export default SectionInfo0
