import React, {Component} from 'react';
import lib from 'library'

const styles ={
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 150,
    //backgroundColor: 'rgba(57,169,71,1)',
    //fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  container:{
    width: 1024,
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    color: 'rgba(53,107,179,1)',
    fontSize: 30,
    fontFamily: 'HelveticaNeueLTStd-Bd',
  },
  content: {
    color: 'black',
    fontSize: 22,
    fontFamily: 'HelveticaNeueLTStd-ThEx',
    padding: 15,
  }
}


class SectionInfo2 extends Component {


  render(){

    const lang = lib.lc.currentLang(this.props.pathname)

    return(
      <div style={styles.footerStyle}>
        <div style={styles.container}>

          <div style={{
              display: 'flex',
              flex: 1,
            }}>
            <p style={styles.content}>
              {lc[lang].info1}
              </p>
          </div>
          <div style={{
              maxWidth: 350,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>

          </div>



        </div>

      </div>
    )
  }
}

const lc = {
  en:{
    info1: "From the customer's creativity and ideas, provide different perspectives and more comprehensive and complete consultation and planning, to promote the customer's requirements into products.",
  },
  zh:{
    info1: '從客戶的創造力和想法，提供不同視角以及更全面完整之諮詢與策劃，促使客戶的要求轉化成產品。',
  }
}

export default SectionInfo2
