import React, {Component} from 'react';
import lib from 'library'

const styles ={
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 150,
    //backgroundColor: 'rgba(57,169,71,1)',
    //fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  container:{
    width: 1024,
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    color: 'rgba(53,107,179,1)',
    fontSize: 30,
    fontFamily: 'HelveticaNeueLTStd-Bd',
  },
  content: {
    color: 'black',
    fontSize: 22,
    fontFamily: 'HelveticaNeueLTStd-ThEx',
    padding: 15,
  }
}


class SectionInfo3 extends Component {


  render(){

    const lang = lib.lc.currentLang(this.props.pathname)

    return(
      <div style={styles.footerStyle}>
        <div style={styles.container}>

          <div style={{
              display: 'flex',
              flex: 1,
            }}>
            <p style={styles.content}>
              {lc[lang].info1}
              <br></br>
              <br></br>
              {lc[lang].info2}
              </p>
          </div>
          <div style={{
              maxWidth: 350,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>

          </div>



        </div>

      </div>
    )
  }
}

const lc = {
  en:{
    info1: "The brand's external image and publicity approach have a bearing on the customer's overall impression of the company.  We provide one-stop brand planning, sales planning and after-sales services to meet the needs of the market and customers.",
    info2: "Promote customers to occupy a favorable position in the market and further business development.",
  },
  zh:{
    info1: '品牌的對外形象及宣傳方式關係著客戶對公司的整體印象，為了針對市場及客戶所需，提供一站式的品牌策劃、銷售企劃及售後服務。',
    info2: "促使客戶在市埸上佔到有利發展的位置，令業務發展更進一步。",
  }
}

export default SectionInfo3
