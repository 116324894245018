import React, {Component} from 'react';
import lib from 'library'

const styles ={
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 150,
    //backgroundColor: 'rgba(57,169,71,1)',
    //fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  container:{
    width: 1024,
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    color: 'rgba(53,107,179,1)',
    fontSize: 30,
    fontFamily: 'HelveticaNeueLTStd-Bd',
  },
  content: {
    color: 'black',
    fontSize: 22,
    fontFamily: 'HelveticaNeueLTStd-ThEx',
    padding: 15,
  }
}


class SectionInfo1 extends Component {


  render(){

    const lang = lib.lc.currentLang(this.props.pathname)

    return(
      <div style={styles.footerStyle}>
        <div style={styles.container}>

          <div style={{
              display: 'flex',
              flex: 1,
            }}>
            <p style={styles.content}>
              {lc[lang].info1}
              </p>
          </div>
          <div style={{
              maxWidth: 350,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>

          </div>



        </div>

      </div>
    )
  }
}

const lc = {
  en:{
    info1: "From designing, processing to production, providing one-stop comprehensive and professional services to meet the requirements of different customers, hoping to improve the company's reputation, enhance customer trust, provide customers with first-class quality, reasonably priced products and services.",
  },
  zh:{
    info1: '由設計、加工到生產，提供一站式全面而專業服務，滿足不同客戶的要求，希望藉此提高公司的信譽、增強顧客信賴，為顧客提供品質一流，價錢合理的產品及服務，非常專業。',
  }
}

export default SectionInfo1
